import { createGlobalStyle } from "styled-components";

/**
 * The global styles for the app that need the theme. Other global styles are in the `/brand/style.css` file.
 */
const GlobalStyle = createGlobalStyle`
body {
  background-color: ${({ theme }) => theme.colors.themed.major};
  font-family: ${({ theme }) => theme.typography.fontFamily};
  color: ${({ theme }) => theme.colors.themed.minor};
}
`;
export default GlobalStyle;
