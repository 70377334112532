import SignInButton from "@/atoms/signInButton";
import UserAccountMenu from "@/atoms/userAccountMenu";
import { firebaseAuth } from "@/firebase";
import { Button } from "@mui/material";
import dynamic from "next/dynamic";
import Image from "next/image";
import { useRouter } from "next/router";
import { Suspense } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { BiImport, BiPlus } from "react-icons/bi";
import { RiHistoryFill } from "react-icons/ri";
import styled, { useTheme } from "styled-components";

const MobileNav = dynamic(() => import("@/molecules/collapsibleNav"), {
  ssr: false,
});

const NavbarController = dynamic(() => import("@/atoms/navbarController"), {
  ssr: false,
});

const Navigation = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 1000;
  width: calc(100vw - 40px);
  height: 38px;
  padding: 0 20px;
  margin: 0px;
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: ${({ theme }) => theme.colors.peripheral.majorVariant};
  box-shadow: 0px -1px 4px 1px rgba(0, 0, 0, 0.25);
  ${({ theme }) =>
    theme.darkMode ? "border-bottom: 1px solid rgba(255, 255, 255, 0.3);" : ""}
`;

const NavLinks = styled.div`
  padding-right: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
  .navlink {
    color: ${({ theme }) => theme.colors.themed.minor};
  }
`;

const SignInFiller = styled.div`
  width: 110px;
  pointer-events: none;
`;

const Logo = styled(Image)`
  margin-left: 10px;
  &.active {
    cursor: pointer;
  }
`;

export default function Header() {
  const router = useRouter();
  const [user, loading] = useAuthState(firebaseAuth);
  const theme = useTheme();

  const desktopNavbar = (
    <Navigation>
      <Logo
        src={theme.darkMode ? "/assets/LogoDark.svg" : "/assets/LogoLight.svg"}
        alt="Logo"
        width={102.857}
        height={45}
        priority
        onClick={() => router.pathname !== "/" && router.push("/")}
        className={router.pathname !== "/" ? "active" : ""}
        draggable={false}
      />
      <NavLinks>
        {user && (
          <Button
            variant="text"
            onClick={() => {
              router.push("/new");
            }}
            onMouseOver={() => {
              router.prefetch("/new");
            }}
            startIcon={<BiPlus />}
            className="navlink"
          >
            Create
          </Button>
        )}
        <Button
          variant="text"
          onClick={() => router.push("/import")}
          onMouseOver={() => {
            router.prefetch("/import");
          }}
          startIcon={<BiImport />}
          className="navlink"
        >
          Import
        </Button>
        {user && (
          <Button
            variant="text"
            onClick={() => {
              router.push("/sets");
            }}
            onMouseOver={() => {
              router.prefetch("/sets");
            }}
            startIcon={<RiHistoryFill />}
            className="navlink"
          >
            My Sets
          </Button>
        )}
        {/* <Filler /> */}
        {!loading && user ? (
          <UserAccountMenu user={user} />
        ) : loading ? (
          <SignInFiller />
        ) : !user ? (
          <>
            <Button
              variant="text"
              onClick={() => router.push("/account/auth")}
              onMouseOver={() => {
                router.prefetch("/account/auth");
              }}
              className="navlink"
            >
              Sign In
            </Button>
            <SignInButton />
          </>
        ) : (
          <SignInFiller />
        )}
      </NavLinks>
    </Navigation>
  );

  return (
    <Suspense fallback={desktopNavbar}>
      <NavbarController
        mobile={<MobileNav user={user} />}
        desktop={desktopNavbar}
      />
    </Suspense>
  );
}
