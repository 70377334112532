import ContentFallback from "@/atoms/global-wrappers/contentFallback";
import Header from "@/organisms/header";
import dynamic from "next/dynamic";
import { Suspense } from "react";
const Footer = dynamic(() => import("@/organisms/footer"), {
  ssr: false,
});

/**
 * This component is used to wrap the static components that are loaded (i.e. the header and footer)
 * This is only used to wrap the header and footer so that components that trigger an error or suspense boundary in the header or footer hit a fallback. The inner content has its own fallback to prevent the header and footer from being hidden during a trigger.
 * @returns The page with header and footer according to the matcher
 */
export default function StaticLayer({
  children,
  matcher,
}: {
  /**
   * The inner body of the page
   */
  children: React.ReactNode;
  /**
   * A function to determine whether the header and footer should be rendered
   * @returns A boolean for each of the header and footer to determine whether they should be rendered
   */
  matcher: () => {
    header: boolean;
    footer: boolean;
  };
}) {
  const { header, footer } = matcher();
  return (
    <>
      {header && <Header />}
      <ContentFallback>{children}</ContentFallback>
      <Suspense>{footer && <Footer />}</Suspense>
    </>
  );
}
