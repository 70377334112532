import { Toaster } from "react-hot-toast";

/**
 * Provides a styled toaster component for react-hot-toast toasts to appear in
 * @returns Just the Toaster origin from react-hot-toast
 * @see https://react-hot-toast.com/docs/toaster
 */
export default function WithToast() {
  return (
    <Toaster
      containerStyle={{
        fontFamily: "'Figtree', sans-serif",
        top: "70px",
        zIndex: 1400,
      }}
      gutter={8}
      toastOptions={{
        style: {
          borderRadius: "12px",
        },
      }}
    />
  );
}
