import { createTheme } from "@mui/material";

export const muiLightTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#005e7c",
    },
    secondary: {
      main: "#dc5e41",
    },
    background: {
      default: "#f5f5f5",
    },
    text: {
      primary: "#040f16",
    },
    error: {
      main: "#f44336",
    },
    warning: {
      main: "#faad14",
    },
    info: {
      main: "#023749",
    },
  },
  typography: {
    fontFamily: "'Figtree', sans-serif",
    button: {
      textTransform: "none",
      fontSize: "1rem",
      lineHeight: 1.5,
    },
    h2: {
      fontSize: "2.4rem",
      fontWeight: 600,
    },
    h3: {
      fontSize: "1rem",
      fontWeight: 500,
    },
  },
  shape: {
    borderRadius: 12,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1920,
    },
  },
});

export const muiDarkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#0298ca",
    },
    secondary: {
      main: "#dc5e41",
    },
    background: {
      default: "#040f16",
    },
    text: {
      primary: "#f5f5f5",
    },
    error: {
      main: "#f44336",
    },
    warning: {
      main: "#faad14",
    },
    info: {
      main: "#005e7c",
    },
  },
  typography: {
    fontFamily: "'Figtree', sans-serif",
    button: {
      textTransform: "none",
      fontSize: "1rem",
      lineHeight: 1.5,
    },
    h2: {
      fontSize: "2.4rem",
      fontWeight: 600,
    },
    h3: {
      fontSize: "1rem",
      fontWeight: 500,
    },
  },
  shape: {
    borderRadius: 12,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1920,
    },
  },
});
