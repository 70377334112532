import dynamic from "next/dynamic";
import { Suspense, useState } from "react";
import { useEffectOnce } from "usehooks-ts";
const LinearProgress = dynamic(() => import("@mui/material/LinearProgress"), {
  suspense: true,
});

export default function RenderFallback({
  children,
}: {
  children: React.ReactNode;
}) {
  const [isMounted, setIsMounted] = useState(false);

  useEffectOnce(() => {
    setIsMounted(true);
  });

  return (
    <>
      <Suspense
        fallback={
          <Suspense fallback={"Loading..."}>
            <LinearProgress />
          </Suspense>
        }
      >
        <div
          style={{
            visibility: isMounted ? "visible" : "hidden",
          }}
        >
          {children}
        </div>
      </Suspense>
    </>
  );
}
