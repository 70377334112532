import { Button, Grow } from "@mui/material";
import { useRouter } from "next/router";
import styled from "styled-components";

const StyledButton = styled(Button)`
  ${({ theme }) =>
    theme.darkMode ? `color: ${theme.colors.themed.minor};` : ""}
  ${({ theme }) =>
    theme.darkMode ? `border-color: ${theme.colors.themed.minor};` : ""}
`;

/**
 * A button that links to the sign in page. It grows in to prevent a layout shift when auth state is determined.
 * It also prefetches the sign in page before navigating to it.
 *
 * 🚨 This component ONLY says "Sign up for free" and does not change to "Sign in".
 * * 🛟 A separate component, likely a simple link, should be used to link to the sign in page.
 */
export default function SignInButton() {
  const router = useRouter();

  return (
    <Grow in={true} mountOnEnter>
      <StyledButton
        variant="outlined"
        onClick={() => {
          router.push(
            "/account/auth?referrer=" + router.asPath + "&type=signup",
            "/account/auth"
          );
        }}
        type="button"
        disableRipple={router.pathname === "/account/auth"}
        onMouseOver={() => {
          router.prefetch("/account/auth");
        }}
      >
        Sign up for free
      </StyledButton>
    </Grow>
  );
}
