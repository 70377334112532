import ErrorBoundaryComponent from "@/atoms/errorBoundary";
import DynamicLayer from "@/atoms/global-fallbacks/dynamicLayer";
import RenderFallback from "@/atoms/global-fallbacks/renderFallback";
import StaticLayer from "@/atoms/global-fallbacks/staticLayer";
import Themed from "@/atoms/global-fallbacks/theming";
import WithDefaultSEO from "@/atoms/global-wrappers/defaultSEO";
import WithToast from "@/atoms/global-wrappers/toastable";
import NewVersionDialog from "@/atoms/newVersionDialog";
import "@/brand/normalize.css";
import "@/brand/style.css";
import useAuthCookie from "@/hooks/useAuthCookie";
import { Analytics } from "@vercel/analytics/react";
import type { AppProps } from "next/app";
import { useEffect, useState } from "react";

export default function MyApp({
  Component,
  pageProps,
  router,
}: AppProps): JSX.Element {
  const url = `https://2am.kalinpatel.me${router.route}`;
  const [newVersionAvailable, setNewVersionAvailable] = useState(false);

  const authCookie = useAuthCookie();

  useEffect(() => {
    if (
      typeof window !== "undefined" &&
      "serviceWorker" in navigator &&
      window.workbox !== undefined
    ) {
      const wb = window.workbox;
      const promptNewVersionAvailable = (event: any) => {
        setNewVersionAvailable(true);
      };
      wb.addEventListener("waiting", promptNewVersionAvailable);
    }
  }, []);

  return (
    <>
      <Analytics />
      <WithDefaultSEO url={url} />
      <WithToast />
      <Themed>
        <NewVersionDialog
          open={newVersionAvailable}
          onClose={() => setNewVersionAvailable(false)}
          onAccept={() => {
            if (
              typeof window !== "undefined" &&
              "serviceWorker" in navigator &&
              window.workbox !== undefined
            ) {
              const wb = window.workbox;
              wb.addEventListener("controlling", (event: any) => {
                window.location.reload();
              });
              wb.messageSkipWaiting();
            } else {
              setNewVersionAvailable(false);
            }
          }}
        />

        <StaticLayer
          matcher={() => {
            const headerLessPages = [
              "/study/[setId]/flashcards",
              "/study/[setId]/learn",
              "/study/[setId]/test",
            ];
            const footerLessPages = [
              "/account/auth",
              "/authRedirect",
              ...headerLessPages,
            ];
            const header = !headerLessPages.includes(router.route);
            const footer = !footerLessPages.includes(router.route);
            return { header, footer };
          }}
        >
          <RenderFallback>
            <ErrorBoundaryComponent>
              <DynamicLayer>
                <Component {...pageProps} canonical={url} key={router.route} />
              </DynamicLayer>
            </ErrorBoundaryComponent>
          </RenderFallback>
        </StaticLayer>
      </Themed>
    </>
  );
}
