import { firebaseAuth } from "@/firebase";
import { FirebaseError } from "firebase";
import { onIdTokenChanged } from "firebase/auth";
import { useRouter } from "next/router";
import nookies from "nookies";
import { useEffect } from "react";
import useWindowFocus from "use-window-focus";

interface AuthCookieState {
  /**
   *  Gets the token from the cookie at the time of the call
   * @returns the token if it exists, undefined otherwise
   */
  getToken: () => string | undefined;
  /**
   * Checks if the token exists in the cookie. Useful for troubleshooting rejected API requests.
   *
   * 🚨 WARNING: This does NOT check the validity of the token. API calls may reject if the token is outdated.
   * * 🛟 Refresh the page or call getToken() to get a valid token.
   * @returns true if the token exists, false otherwise
   * @see https://firebase.google.com/docs/auth/admin/verify-id-tokens
   * @see https://firebase.google.com/docs/reference/js/auth.IdTokenResult
   */
  isTokenSet: () => boolean;
}

/**
 * A hook to set and update the auth cookie from Firebase
 * @returns an object with functions to get the token and check if the token exists
 * @see https://firebase.google.com/docs/reference/js/auth.auth.md#authonidtokenchanged
 */
export default function useAuthCookie(): AuthCookieState {
  const router = useRouter();
  const windowFocused = useWindowFocus();
  useEffect(() => {
    return onIdTokenChanged(firebaseAuth, (user) => {
      if (user) {
        user.getIdTokenResult().then((idTokenResult) => {
          nookies.set(undefined, "token", idTokenResult.token, {
            path: "/",
          });
        });
      } else {
        nookies.destroy(undefined, "token", {
          path: "/",
        });
      }
    });
  }, []);

  useEffect(() => {
    const user = firebaseAuth.currentUser;
    if (user) user.getIdToken(true);
    const handle = setInterval(async () => {
      const user = firebaseAuth.currentUser;
      try {
        if (user) await user.getIdToken(true);
      } catch (err) {
        const error = err as FirebaseError;
        if (error.code === "auth/network-request-failed") {
          console.error("Network error while refreshing token");
          return;
        }
        console.error("Error refreshing token", err);
      }
    }, 2 * 60 * 1000 /* 2 minutes */);

    // clean up setInterval
    return () => clearInterval(handle);
  }, []);

  useEffect(() => {
    const user = firebaseAuth.currentUser;
    if (user) user.getIdToken(true);
  }, [router.asPath, windowFocused]);

  return {
    getToken: () => nookies.get(undefined, "token")?.token,
    isTokenSet: () => !!nookies.get(undefined, "token")?.token,
  };
}
