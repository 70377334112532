import UserAccountDropdown from "@/atoms/userAccountDropdown";
import { Avatar, IconButton, Tooltip } from "@mui/material";
import type { UserInfo } from "firebase";
import { useEffect, useState } from "react";

interface UserAccountMenuProps {
  /**
   * The user's information to be displayed in the dropdown menu
   */
  user: UserInfo | null;
}

type HasPicture = {
  /**
   * The URL (social or uploaded)
   */
  src: string;
};

type HasInitials = {
  /**
   * The user's initials, if no picture is available
   */
  initials: string;
};

type HasNoPicture = {
  /**
   * Whether the user has no picture
   */
  empty: true;
};

/**
 * The user's picture, if available, or their initials. If none are available, the user has no picture.
 */
export type UserPicture = HasPicture | HasInitials | HasNoPicture;

export default function UserAccountMenu({ user }: UserAccountMenuProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [userIconPicture, setUserIconPicture] = useState<UserPicture>({
    empty: true,
  });
  function changeUserIconPicture(userPictureURL: any, userDisplayName: any) {
    if (userPictureURL) {
      setUserIconPicture({
        src: userPictureURL,
      });
    } else if (userDisplayName) {
      setUserIconPicture({
        initials: userDisplayName
          .split(" ")
          .map((n: string) => n[0])
          .join(""),
      });
    } else {
      setUserIconPicture({ empty: true });
    }
  }
  useEffect(() => {
    changeUserIconPicture(user?.photoURL, user?.displayName);
  }, [user]);

  return (
    <>
      <Tooltip title="Account settings">
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          {"src" in userIconPicture ? (
            <Avatar
              sx={{ width: 32, height: 32 }}
              src={userIconPicture.src}
              alt=""
            />
          ) : "initials" in userIconPicture ? (
            <Avatar sx={{ width: 32, height: 32 }}>
              {userIconPicture.initials}
            </Avatar>
          ) : (
            <Avatar sx={{ width: 32, height: 32 }} />
          )}
        </IconButton>
      </Tooltip>
      <UserAccountDropdown
        anchor={anchorEl}
        isOpen={open}
        onClose={handleClose}
        user={user}
        userIcon={userIconPicture}
      />
    </>
  );
}
