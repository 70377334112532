import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { MdClose } from "react-icons/md";

interface NewVersionDialogProps {
  /**
   * Whether to show the dialog
   */
  open: boolean;
  /**
   * The function to call when the user confirms the action (include closing the dialog in this function)
   */
  onAccept: () => void;
  /**
   * The function to call when the user cancels the action (include closing the dialog in this function)
   */
  onClose: () => void;
}

/**
 * A dialog to notify the user that a new version of the app is available.
 */
export default function NewVersionDialog({
  open,
  onAccept,
  onClose,
}: NewVersionDialogProps) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        Update available
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <MdClose />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          A new version of the app is available. Click reload to refresh the
          page and update the app.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onAccept} color="primary" autoFocus>
          Reload
        </Button>
      </DialogActions>
    </Dialog>
  );
}
