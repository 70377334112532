import dynamic from "next/dynamic";
import { Suspense } from "react";
const LinearProgress = dynamic(() => import("@mui/material/LinearProgress"), {
  suspense: true,
});

/**
 * Suspense boundary that shows a LinearProgress when the content is loading (and "Loading..." when the LinearProgress is loading). LinearProgress is loaded dynamically (with a suspense fallback) to optimize the initial page load.
 * @see https://mui.com/components/progress/#linear
 */
export default function ContentFallback({
  children,
}: {
  /**
   * The children that may trigger a suspense boundary
   */
  children: React.ReactNode;
}) {
  return (
    <>
      <Suspense
        fallback={
          <Suspense fallback={"Loading..."}>
            <LinearProgress />
          </Suspense>
        }
      >
        {children}
      </Suspense>
    </>
  );
}
