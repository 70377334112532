const LinearProgress = dynamic(() => import("@mui/material/LinearProgress"), {
  suspense: true,
});
import { AnimatePresence } from "framer-motion";
import dynamic from "next/dynamic";
import { Suspense } from "react";

/**
 * This component is used to wrap the dynamic components that are loaded (i.e. the page)
 * This allows us to use the suspense fallback and animate the page transitions
 * @returns The page with with suspense and animations handled
 */
export default function DynamicLayer({
  children,
}: {
  /**
   * The inner body of the page
   */
  children: React.ReactNode;
}) {
  return (
    <Suspense fallback={<LinearProgress color="secondary" />}>
      <AnimatePresence
        mode="wait"
        initial={false}
        onExitComplete={() => window.scrollTo(0, 0)}
      >
        {children}
      </AnimatePresence>
    </Suspense>
  );
}
