interface NonColorTheme {
  typography: {
    fontFamily: string;
    contentFontFamily: string;
    fontSize: {
      default: string;
      large: string;
      small: string;
    };
  };
  borderRadius: {
    default: string;
    large: string;
    small: string;
  };
  headerHeight: string;
  breakpoints: {
    sm: string;
    md: string;
    lg: string;
    xl: string;
  };
}

export type ThemeOptions = "light" | "dark" | "system";

export interface Theme extends NonColorTheme {
  darkMode: boolean;
  colors: {
    themed: {
      major: string;
      minor: string;
      alwaysLight: string;
      alwaysDark: string;
    };
    brand: {
      primary: string;
      accent: string;
      primary1: string;
      primary2: string;
      primary3: string;
      primaryAlt1: string;
      primaryAlt2: string;
      primaryAlt3: string;
      color1: string;
      color2: string;
      color3: string;
      color4: string;
      color5: string;
      color1RGB: string;
      color2RGB: string;
      color3RGB: string;
      color4RGB: string;
      color5RGB: string;
      primaryRGB: string;
    };
    action: {
      success: string;
      error: string;
      warning: string;
      errorRGB: string;
      successRGB: string;
      warningRGB: string;
    };
    peripheral: {
      majorVariant: string;
      minorVariant: string;
      secondary1: string;
      secondary2: string;
      secondary3: string;
    };
  };
}

const defaultTheme: NonColorTheme = {
  typography: {
    fontFamily: "'Figtree', sans-serif",
    contentFontFamily: "'STIX Two Text', serif",
    fontSize: {
      default: "1.2rem",
      small: "1rem",
      large: "2.4rem",
    },
  },
  headerHeight: "54px",
  borderRadius: {
    default: "12px",
    large: "14px",
    small: "6px",
  },
  breakpoints: {
    sm: "576px",
    md: "768px",
    lg: "992px",
    xl: "1120px",
  },
};

// Light
export const lightTheme: Theme = {
  colors: {
    themed: {
      major: "#f5f5f5",
      minor: "#040f16",
      alwaysLight: "#f5f5f5",
      alwaysDark: "#040f16",
    },
    brand: {
      primary: "#005e7c",
      accent: "#dc5e41",
      primary1: "#7BAAB9",
      primary2: "#B8D0D7",
      primary3: "#D7E3E6",
      primaryAlt1: "#023749",
      primaryAlt2: "#032330",
      primaryAlt3: "#041923",
      color1: "#058C42",
      color2: "#F2C94C",
      color3: "#F2994A",
      color4: "#EB5757",
      color5: "#8D5A97",
      color1RGB: "5, 140, 66",
      color2RGB: "242, 201, 76",
      color3RGB: "242, 153, 74",
      color4RGB: "235, 87, 87",
      color5RGB: "141, 90, 151",
      primaryRGB: "0, 94, 124",
    },
    action: {
      error: "#f44336",
      success: "#4caf50",
      warning: "#faad14",
      errorRGB: "244, 67, 54",
      successRGB: "76, 175, 80",
      warningRGB: "250, 173, 20",
    },
    peripheral: {
      majorVariant: "#ffffff",
      minorVariant: "#353535",
      secondary1: "#ddd",
      secondary2: "#999",
      secondary3: "#444",
    },
  },
  ...defaultTheme,
  darkMode: false,
};

// Dark
export const darkTheme: Theme = {
  colors: {
    themed: {
      minor: "#f5f5f5",
      major: "#040f16",
      alwaysLight: "#f5f5f5",
      alwaysDark: "#040f16",
    },
    brand: {
      primary: "#0298CA",
      accent: "#dc5e41",
      primaryAlt1: "#7BAAB9",
      primaryAlt2: "#B8D0D7",
      primaryAlt3: "#D7E3E6",
      primary1: "#023749",
      primary2: "#032330",
      primary3: "#041923",
      color1: "#058C42",
      color2: "#F2C94C",
      color3: "#F2994A",
      color4: "#EB5757",
      color5: "#8D5A97",
      color1RGB: "5, 140, 66",
      color2RGB: "242, 201, 76",
      color3RGB: "242, 153, 74",
      color4RGB: "235, 87, 87",
      color5RGB: "141, 90, 151",
      primaryRGB: "0, 94, 124",
    },
    action: {
      error: "#f44336",
      success: "#4caf50",
      warning: "#ffc53d",
      errorRGB: "244, 67, 54",
      successRGB: "76, 175, 80",
      warningRGB: "255, 197, 61",
    },
    peripheral: {
      minorVariant: "#ffffff",
      majorVariant: "#353535",
      secondary3: "#ddd",
      secondary2: "#999",
      secondary1: "#444",
    },
  },
  ...defaultTheme,
  darkMode: true,
};
