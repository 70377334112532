import type { UserPicture } from "@/atoms/userAccountMenu";
import { firebaseAuth } from "@/firebase";
import { LoadingButton } from "@mui/lab";
import {
  Avatar,
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import type { UserInfo } from "firebase";
import { signOut } from "firebase/auth";
import { useRouter } from "next/router";
import { useState } from "react";
import { MdSettings } from "react-icons/md";
import styled from "styled-components";

interface UserAccountDropdownProps {
  /**
   * The anchor element for the dropdown menu to open on
   */
  anchor: HTMLElement | null;
  /**
   * The function to call when the dropdown menu is closed
   */
  onClose: () => void;
  /**
   * The user's information to be displayed in the dropdown menu
   */
  user: UserInfo | null;
  /**
   * The user's icon to be displayed in the dropdown menu
   */
  userIcon: UserPicture;
  /**
   * Whether the dropdown menu is open
   */
  isOpen: boolean;
}

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-left: 8px;
`;
const StrongName = styled.span`
  font-weight: 500;
`;
const Email = styled.span`
  font-size: 0.8rem;
  font-weight: 300;
`;

/**
 * A dropdown menu for the user's account
 */
export default function UserAccountDropdown({
  anchor,
  onClose,
  isOpen,
  userIcon,
  user,
}: UserAccountDropdownProps) {
  const router = useRouter();
  const [isSigningOut, setIsSigningOut] = useState(false);
  return (
    <Menu
      anchorEl={anchor}
      id="account-menu"
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        elevation: 0,
        sx: {
          minWidth: 200,
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&:before": {
            content: "''",
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <MenuItem disableRipple disableTouchRipple sx={{ pointerEvents: "none" }}>
        {"src" in userIcon ? (
          <Avatar sx={{ width: 32, height: 32 }} src={userIcon.src} />
        ) : "initials" in userIcon ? (
          <Avatar sx={{ width: 32, height: 32 }}>{userIcon.initials}</Avatar>
        ) : (
          <Avatar sx={{ width: 32, height: 32 }} />
        )}{" "}
        <InfoWrapper>
          <StrongName>{user?.displayName}</StrongName>
          <Email>{user?.email}</Email>
        </InfoWrapper>
      </MenuItem>
      <Divider />
      <MenuItem
        onClick={() => {
          router.push("/account/settings");
          onClose();
        }}
      >
        <ListItemIcon>
          <MdSettings />
        </ListItemIcon>
        <ListItemText>Settings</ListItemText>
      </MenuItem>
      <Divider />
      <MenuItem
        disableRipple
        disableTouchRipple
        sx={{
          "&:hover": { backgroundColor: "transparent", cursor: "default" },
        }}
      >
        <LoadingButton
          variant="contained"
          color="primary"
          fullWidth
          loading={isSigningOut}
          loadingPosition="center"
          onClick={async () => {
            setIsSigningOut(true);
            await signOut(firebaseAuth);
            setIsSigningOut(false);
            router.push("/account/auth");
            onClose();
          }}
        >
          Sign out
        </LoadingButton>
      </MenuItem>
    </Menu>
  );
}
