import { DefaultSeo } from "next-seo";

interface DefaultSEOWrapperProps {
  /**
   * The URL of the page. This is used for the canonical URL and the OpenGraph URL.
   */
  url: string;
}

/**
 * A wrapper for the DefaultSeo component that sets the default SEO values.
 */
export default function WithDefaultSEO({ url }: DefaultSEOWrapperProps) {
  return (
    <DefaultSeo
      titleTemplate="%s | 2 AM"
      defaultTitle="2 AM"
      description="Learning, memorization, and practice tools for teachers and students."
      twitter={{
        cardType: "summary_large_image",
      }}
      openGraph={{
        type: "website",
        locale: "en_US",
        url: url,
        description: "A free way to study efficiently.",
        site_name: "2 AM",
        images: [
          {
            url: "https://2am.kalinpatel.me/assets/opengraph.png",
            width: 1200,
            height: 630,
            alt: "2 AM",
          },
        ],
      }}
      canonical={url}
    />
  );
}
