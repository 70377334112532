import type { ThemeOptions } from "@/brand/theme";
import { createContext } from "react";

type ThemeContextType = {
  /**
   * The current theme setting: "light", "dark", or "system"
   */
  setting: ThemeOptions;
  /**
   * Sets the theme setting
   * @param setting The new theme setting: "light", "dark", or "system"
   */
  setSetting: (setting: ThemeOptions) => void;
};

export const ThemeContext = createContext<ThemeContextType>({
  setting: "light",
  setSetting: () => undefined,
});
