import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, OAuthProvider } from "firebase/auth";
import { firebaseConfig } from "./configs";

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
export const firebaseAuth = getAuth(firebaseApp);

// Firebase Auth Providers
export const firebaseProviderGoogle = new GoogleAuthProvider();
export const firebaseProviderMicrosoft = new OAuthProvider("microsoft.com");

// Initialize Firebase Analytics
// NOTE: this has been replaced by Splitbee (Vercel Analytics)
// let fbA;
// if (typeof window !== "undefined") {
//   fbA = getAnalytics(firebaseApp);
// }
// export const firebaseAnalytics = fbA;
