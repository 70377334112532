import dynamic from "next/dynamic";
import { Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
const Alert = dynamic(() => import("@mui/material/Alert"), {
  suspense: true,
});
const AlertTitle = dynamic(() => import("@mui/material/AlertTitle"), {
  suspense: true,
});

function ErrorFallback({ error }: { error: any }) {
  return (
    <Suspense
      fallback={
        <>An error occurred. Please wait while we find out what it is.</>
      }
    >
      <Alert severity="error" sx={{ height: "100%" }}>
        <AlertTitle>An error occurred</AlertTitle>
        <pre>{error.message}</pre>
      </Alert>
    </Suspense>
  );
}

/**
 * Catches errors and displays them to the user. The alert to display errors is loaded dynamically (with a suspense fallback) to optimize the initial page load. This component is under 7kb gzipped and is performant enough to include whenever necessary.
 */
export default function ErrorBoundaryComponent({
  children,
}: {
  /**
   * The children to render (and catch errors for)
   */
  children: any;
}) {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>{children}</ErrorBoundary>
  );
}
