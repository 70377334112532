import GlobalStyle from "@/atoms/global-wrappers/globalStyle";
import { ThemeContext } from "@/atoms/global-wrappers/themeContext";
import { muiDarkTheme, muiLightTheme } from "@/brand/muiTheme";
import type { ThemeOptions } from "@/brand/theme";
import { darkTheme, lightTheme } from "@/brand/theme";
import { ThemeProvider as MUIThemeProvider } from "@mui/material";
import { ThemeProvider } from "styled-components";
import { useTernaryDarkMode } from "usehooks-ts";

/**
 * This component is used to wrap the entire app with the theme provider for Styled Components and MUI and dark/light mode
 * @returns The page with the theme provider context
 * @see https://styled-components.com/docs/advanced#theming
 * @see https://mui.com/customization/theming/
 * @see https://usehooks-typescript.com/react-hook/use-ternary-dark-mode
 */
export default function Themed({
  children,
}: {
  /**
   * The inner body of the page
   */
  children: React.ReactNode;
}) {
  const { isDarkMode, ternaryDarkMode, setTernaryDarkMode } =
    useTernaryDarkMode();

  return (
    <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
      <MUIThemeProvider theme={isDarkMode ? muiDarkTheme : muiLightTheme}>
        <GlobalStyle />
        <ThemeContext.Provider
          value={{
            setting: ternaryDarkMode as ThemeOptions,
            setSetting: setTernaryDarkMode,
          }}
        >
          {children}
        </ThemeContext.Provider>
      </MUIThemeProvider>
    </ThemeProvider>
  );
}
